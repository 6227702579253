const styles = (theme) => ({
    '.leaders-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '1100px',
        margin: 'auto',
        padding: '60px 0',
        [theme.breakpoints.down('mobile')]: {
            width: 'auto',
            padding: '40px 24px',
        },

        '.header': {
            textAlign: 'center',
            marginBottom: '60px',
            [theme.breakpoints.down('mobile')]: {
                padding: '24px 24px',
                marginBottom: '0',
            },

            '.margin': {
                marginBottom: '24px',
                [theme.breakpoints.down('mobile')]: {
                    marginBottom: '',
                },
            },
        },

        '.leaders': {
            display: 'grid',
            gridTemplateColumns: '312px auto',
            width: '100%',
            gap: '40px',
            [theme.breakpoints.down('mobile')]: {
                gridTemplateColumns: 'auto',
                gridTemplateRows: 'auto auto',
            },

            '.col-one': {
                textAlign: 'center',
                borderRight: '1px solid #0B2A471A',
                '&:hover': {
                    cursor: 'pointer',
                    
                    '.hover-text': {
                        color: theme.custom.colors.primary_teal,
                    },

                    '#azizi-colored-overlay-display': {
                        display: 'block',
                    },
                },
                [theme.breakpoints.down('mobile')]: {
                    padding: '24px 24px',
                    borderRight: 'initial',
                    borderBottom: `1px solid #0b2a474d`,
                },

                '.img-wrapper': {
                    borderRadius: '227px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    maxWidth: '116px',
                    height: '116px',

                    'img': {
                        // height: '116px',
                        position: 'relative',
                        right: '40px',
                        height: '116px',
                    },

                    '.azizi-image': {
                        right: '77px',
                        height: '161px',
                    },

                    '.azizi-colored-overlay': {
                        display: 'none',
                        minWidth: '116px',
                        maxWidth: '116px',
                        minHeight: '116px',
                        maxHeight: '116px',
                        position: 'absolute',
                        zIndex: '2',
                        backgroundColor: theme.custom.colors.primary_teal,
                        borderRadius: '227px',
                        filter: 'opacity(25%)',
                    },
                },
                '.tags-container': {
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    rowGap: (theme) => theme.custom.tag.gap,
                    columnGap: (theme) => theme.custom.tag.gap,
                },
                '.subtitle': {
                    fontFamily: 'Noto Sans',
                    fontSize: '14px',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    lineHeight: '24px',
                }
            },
            '.col-two': {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                rowGap: '56px',
                columnGap: '40px',
            }
        }
    }
});

export default styles;