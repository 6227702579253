const styles = (theme) => ({    
  ".dialog-body": {
    backgroundColor: theme.custom.colors.primary_navy,
    width: "100vw",
    height: '100vh',
    paddingTop: '80px',
    color: theme.custom.colors.primary_white,
    [theme.breakpoints.down('mobile')]: {
      width: 'initial',
      height: 'initial'
    },

    '.dialog-close': {
      display: 'inline',
      position: 'absolute',
      marginRight: '5vw',
      right: '0',
      [theme.breakpoints.down('mobile')]: {
        top: '40px',
      },

      'svg': {
        color: 'white',
        width: '1.2em',
        height: '1.2em',
      },
    },

    '.margin-bottom-24': {
        marginBottom: '24px',
    },

    '.margin-bottom-16': {
        marginBottom: '16px',
    },

    '.margin-bottom-40': {
        marginBottom: '40px',
    },

    '.dialog-content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        margin: 'auto',
        padding: '20px 20px 0 20px',

        'p': {
            fontFamily: 'Noto Sans',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
        },

        '.tag-group': {
            display: 'flex',
            columnGap: '8px',
            [theme.breakpoints.down('mobile')]: {
              flexWrap: 'wrap',
              rowGap: '8px'
            },
        },

        'button': {
            borderColor: theme.custom.colors.primary_white,
        },
    },

    ".tag-container": {
      backgroundColor: 'rgba(255, 255, 255, 0.20)',
      borderRadius: (theme) => theme.custom.tag.border_radius,
      padding: (theme) => theme.custom.tag.padding,
      width: "fit-content",
      fontFamily: "Noto sans",
      fontSize: (theme) => theme.custom.tag.fontSize,
      fontWeight: "600",
      lineHeight: "16px",
    },

    ".dialog-img-wrapper": {
      borderRadius: "227px",
      display: "inline-block",
      overflow: "hidden",
      minWidth: '116px',
      maxWidth: "116px",
      minHeight: "116px",
      maxHeight: '116px',

      img: {
        // height: '116px',
        position: "relative",
        height: "116px",
        [theme.breakpoints.down('mobile')]: {
          height: '116px'
        },
      },

      ".colored-overlay": {
        minWidth: "116px",
        maxWidth: "116px",
        minHeight: "116px",
        maxHeight: "116px",
        position: "absolute",
        zIndex: "2",
        backgroundColor: theme.custom.colors.primary_teal,
        borderRadius: "227px",
        filter: "opacity(25%)",
      },
    },
  },
});

export default styles;