import { Box, Typography } from '@mui/material'
import React from 'react'

import styles from './footerStyles'

function Footer() {
  return (
    <Box className='footer-container' sx={styles}>
        <Box className='footer-content'>
            <Typography variant='logo'>HARMONI</Typography>
        </Box>
    </Box>
  )
}

export default Footer