import { Box, Typography } from "@mui/material";
import React from "react";

import styles from "./valuesStyles";

function ValuesSection() {
  return (
    <Box className="values-section-container" sx={styles}>
      <Box className="values-section-content">
        <Box>
          <Typography variant="overline" className="overline">OUR MISSION</Typography>
          <Typography variant="body_copy">
            Innovate, accelerate, and disseminate accessible and effective
            health and wellness technologies for all
          </Typography>
        </Box>
        <Box className='middle-column'>
          <Typography variant="overline" className="overline">OUR VISION</Typography>
          <Typography variant="body_copy">
            Improve patient outcomes for all through technology and innovation
          </Typography>
        </Box>
        <Box>
          <Typography variant="overline" className="overline">OUR VALUE</Typography>
          <Typography variant="body_copy">
            A leading voice in healthcare, technology, and innovation that is
            trusted by all
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ValuesSection;
