import { Box } from "@mui/material";

import Header from "./components/header/Header";
import CarouselSection from "./components/carouselSection/CarouselSection";
import MapSection from "./components/mapSection/MapSection";
import ValuesSection from "./components/valuesSection/ValuesSection";
import VideoSection from "./components/videoSection/VideoSection";
import Leaders from "./components/leadersSection/Leaders";
import Contact from "./components/contactSection/Contact";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Box className="main-container">
      <Header />
      <CarouselSection />
      <MapSection />
      <ValuesSection />
      <VideoSection />
      <Leaders />
      <Contact />
      <Footer />
    </Box>
  );
}

export default App;
