import React from 'react'
import { Box, Button, Typography } from '@mui/material'

import styles from './headerStyles';

function Header() {
  const scrollToContactSection = () => {
    document.querySelector(".contact-container").scrollIntoView({behavior: "smooth"});
  }

  return (
    <Box className='header-section' sx={styles['header-section']}>
        <Box className="header-content-container" sx={styles['header-content-container']}>
            <Typography variant='logo'>HARMONI</Typography>
            <Button variant='contained' onClick={scrollToContactSection}>Join us</Button>
        </Box>
    </Box>
  )
}

export default Header;