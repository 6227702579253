const styles = (theme) => ({
    backgroundColor: (theme) => theme.custom.colors.primary_navy,
    borderTop: '1px solid rgba(255, 255, 255, 0.30)',
    padding: '60px',
    color: (theme) => theme.custom.colors.primary_white,
    [theme.breakpoints.down('mobile')]: {
        padding: '0',
    },

    '.footer-content': {
        display: 'flex',
        minHeight: '100px',
        alignItems: 'center',
        [theme.breakpoints.down('mobile')]: {
            padding: '0 24px',
        },
    }
});

export default styles;