const styles = (theme) => ({
    '.values-section-content': {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        columnGap: '40px',
        padding: '0 170px 60px 170px',
        textAlign: 'center',
        [theme.breakpoints.down('mobile')]: {
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'auto auto auto',
            padding: '0 24px 40px 24px',
            rowGap: '40px',
        },

        '.middle-column': {
            borderLeft: '1px solid #0B2A471A',
            borderRight: '1px solid #0B2A471A',
            paddingLeft: '35px',
            paddingRight: '35px',
            [theme.breakpoints.down('mobile')]: {
                borderLeft: 'initial',
                borderRight: 'initial',
                borderTop: '1px solid #0B2A471A',
                borderBottom: '1px solid #0B2A471A',
                paddingTop: '35px',
                paddingBottom: '35px',
            },
        },

        '.overline': {
            color: (theme) => theme.custom.colors.primary_teal,
            marginBottom: '8px',
        }
    }
})

export default styles;