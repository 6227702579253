import { Box, Typography } from '@mui/material'
import React from 'react'

import styles from './mapSectionStyles'

function MapSection() {
  return (
    <Box className='map-section-container' sx={styles}>
        <Box className='map-section-content' >
            <Typography variant='heading1' className='heading-width1' role='heading' >Becoming the world's largest health data repository</Typography>
            <Typography variant='body_copy' className='heading-width2' >Building a diverse set of data coming together to bring more memories to all</Typography>
            <img src='images/Map_Blank.svg' alt='abstract map' />
        </Box>
    </Box>
  )
}

export default MapSection