import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      mobile: 394,
      desktop: 1440,
    },
  },
  custom: {
    colors: {
      primary_white: "#FFFFFF",
      primary_black: "#000000",
      primary_navy: "#0B2A47",
      primary_teal: "#1B7C8D",
      primary_green: "#A8CB5C",
      blue_highlight: "#C4F0F2",
      tag_background: "rgba(11, 42, 71, 0.10)",
    },
    fonts: {
      heading1: {
        fontWeight: "700",
        fontSize: "40px",
        lineHeight: "48px",
      },
      heading2: {
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "32px",
      },
      heading3: {
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "28px",
      },
      overline: {
        fontWeight: "700px",
        fontSize: "14px",
        lineHeight: "24px",
      },
      body_copy: {
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "24px",
      },
      body_copy_20: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
    buttons: {
      width: "160px",
      height: "24px",
      padding: "12px 24px",
      border_radius: "10px",
      border_width: "2px",
      border_style: "solid",
    },
    tag: {
      border_radius: "30px",
      padding: "2px 16px",
      gap: "6px",
      fontSize: "12px",
    },
    logo: {
      font_size: "16px",
      font_style: "normal",
      font_weight: "900",
      line_height: "24px",
      letter_spacing: "0.8px",
    },
  },
});

// For directly customizing mui
theme = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        input: {
          borderRadius: "6px",
          border: (theme) => `1px solid ${theme.custom.colors.primary_white}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: theme.custom.buttons.border_radius,
          padding: theme.custom.buttons.padding,
          width: theme.custom.buttons.width,
          color: theme.custom.colors.primary_white,
          fontFamily: "Noto Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "24px",
          textTransform: "none",
          ':hover': {
            backgroundColor: theme.custom.colors.primary_green,
          },
        },
        contained: {
          backgroundColor: theme.custom.colors.primary_teal,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
                font-family: 'Noto Sans';
                src: local('Noto Sans'), url(./fonts/NotoSans-VariableFont_wdth\,wght.ttf) format('truetype');
              }
              
            @font-face {
                font-family: 'Noto Sans';
                src: local('Noto Sans'), url(./fonts/NotoSans-Italic-VariableFont_wdth\,wght.ttf) format('truetype');
                font-style: italic;
              }
            `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          heading1: "p",
          heading2: "p",
          heading3: "p",
          overline: "p",
          body_copy: "p",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Noto Sans", "Roboto", "-apple-system"].join(","),
    logo: {
      fontFamily: "Noto Sans",
      fontSize: theme.custom.logo.font_size,
      fontStyle: theme.custom.logo.font_style,
      fontWeight: theme.custom.logo.font_weight,
      lineHeight: theme.custom.logo.line_height,
      letterSpacing: theme.custom.logo.letter_spacing,
    },
    heading1: {
      fontFamily: "Noto Sans",
      fontWeight: "700",
      fontSize: "40px",
      lineHeight: "48px",
    },
    heading2: {
      fontFamily: "Noto Sans",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "32px",
    },
    heading3: {
      fontFamily: "Noto Sans",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "28px",
    },
    overline: {
      fontFamily: "Noto Sans",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "24px",
    },
    body_copy: {
      fontFamily: "Noto Sans",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
});

export default theme;
