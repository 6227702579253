const styles = (theme) => ({
    'img': {
        width: '720px',
        maxWidth: '720px',
        // minHeight: '431px',
        height: '431px',
        [theme.breakpoints.down('mobile')]: {
            width: '100%',
            height: 'auto',
        }
    }
});

export default styles;