const styles = (theme) => ({
    textAlign: 'center',
    padding: '60px 170px 40px 160px',
    [theme.breakpoints.down('mobile')]: {
        padding: '40px 24px',
        'img': {
            width: '100%'
        }
    },

    '.map-section-content': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '24px',
        flex: '1 0',

        '.heading-width1': {
            width: '734px',
            [theme.breakpoints.down('mobile')]: {
                width: 'initial',
            },
        },

        '.heading-width2': {
            width: '520px',
            [theme.breakpoints.down('mobile')]: {
                width: 'initial',
            },
        },
    }
});

export default styles;