const text = {
    azizi1: "Dr. Azizi Seixas is Interim Inaugural Chair of the Department of Informatics and Health Data Science at The University of Miami Miller School of Medicine. He is also Founding Director of The Media and Innovation Lab (The MIL), Associate Director of the Center for Translational Sleep and Circadian Sciences (TSCS) and Director of Population Health Informatics in the Institute for Data Science and Computing. He was recently awarded one of Miami’s Most Influential and Powerful Black Business Leaders, an Education Champion Awardee by Amazon Web Services for his innovative work using cloud computing technology and his advocacy for improving education and has been recognized by Cell Press as one of the top 100 most inspiring Black scientists in America.",
    azizi2: "Dr. Seixas has over 175 high impact peer-reviewed publications, book chapters and conference presentations and his work appears in several media-outlets such as CBS, CNN, NBC, Associated Press, The Guardian, Huffington Post, and is the sleep expert for NBC Health News. He is currently the Chair for the American Academy of Sleep Medicine (AASM) Sleep Medicine Disruptors Conference and Change Agents program, Vice Chair of the AASM Artificial Intelligence and Machine Learning sub-committee, the Director of Early Career Faculty Development for NYU Langone Health’s Department of Population Health, member of the American Heart Association's Cardiac Rehab Advisory Group, permanent member of the NIH’s MESH Study Review Panel, and former Chair of AASM’s Young Investigator Research Forum. He will lead the overall administration, project management, strategy development, and system and domain integration within the HARMONI Alliance. He will work alongside the Systems and Domain Integration team, which consists of members of Slalom and ICF (see below). He will also lead Research Vertical overseeing OKRs, KPIs, and KRIs for Trial and Incubator Cores and will focus on advancing scientific discovery and innovation within the alliance. He will oversee and guide research projects across all cores, ensuring alignment with the alliance’s goals and objectives leveraging her expertise in research to contribute to the advancement of knowledge and the generation of impactful findings within the alliance.",
    freddy: "Ferdinand Zizi, MBA is an accomplished leader with over two decades of experience in overseeing and enhancing programs that promote education, clinical improvement, and research in healthcare systems. In his role as the Executive Director of the HARMONI Alliance, he brings a wealth of expertise to the organization, leading its operations and strategy. Mr. Zizi is dedicated to driving innovation and excellence across the core verticals of the alliance, ensuring that HARMONI continues to make a meaningful impact in the field of healthy aging research and medical outcomes. His commitment to fostering collaboration and sustainable community engagement makes him a vital asset to the alliance's mission of improving lives worldwide.",
    jean: "Dr. Girardin Jean-Louis (Education Vertical) is Professor of Psychiatry and Neurology at the Miller School of Medicine, University of Miami. He is the Director of the Center on Translational Sleep and Circadian Sciences and the PRIDE Institute on Behavioral Medicine and Sleep Disorders Research. He has served on the NIH Sleep Disorders Research Advisory Board, the Cancer, Heart, and Sleep Epidemiology (CHSE-B) study section, the National Advisory Council for National Center for Complementary and Integrative Health, the SRS Board of Directors, and several NIH Special Emphasis Panels/ Scientific Review Groups. Dr. Jean-Louis will lead the Education vertical, which focuses on advancing learning, training, and professional development within the alliance. He will oversee educational programs, curriculum development, and training initiatives across the cores, ensuring the integration of innovative educational methods and technologies. ",
    alberto: "Dr. Alberto Ramos is Clinical professor of Neurology at the University of Miami Miller School of Medicine. I have been involved with the Northern Manhattan Study and the Hispanic Community Health Study/Study of Latinos (HCHS/SOL) since 2010. Further, I was the Miami site-PI for the ancillary sleep study, Sueño (R01HL098297)-Sleep as a Risk Factor for Disease in HCHS/SOL. Sueño aimed to determine the prevalence of abnormal sleep patterns in 2,200 Latinos using actigraphy. I was the PI for an HCHS/SOL ancillary study that evaluated sleep apnea, actigraphic sleep duration with impaired cerebral hemodynamics using transcranial Doppler ultrasound in 98 participants at the Miami field site. The study was supported by a mentored research award from the Clinical Translational Research Institute at the Miller School of Medicine, University of Miami, Miami, FL. Dr. Ramos will Clinical Operations, dissemination, and eHealth [CODE] Vertical will oversee and track OKRs, KPIs, and KRIs for CRN core, which centers on improving clinical care and patient outcomes. He will oversee clinical initiatives and ensure the integration of evidence-based practices and innovations across the alliance’s cores. ",
    nick: "Dr. Nick Tsinoremas (Venture vertical) is the Vice Provost for Research Computing and Data and Founding Director of the University of Miami Institute for Data Science and Computing which was established to catalyze data-intensive research in fields ranging from medicine to earth sciences, urban planning, digital humanities, and business. He also holds faculty appointments in the Miller School of Medicine and the College of Arts and Sciences. Dr. Tsinoremas received his B.A. in Chemistry from the University of Athens, Greece, and his Ph.D. in Biochemistry and Molecular Biology from the University of Leeds, UK. Dr. Tsinoremas’ research focus includes projects that combine data science and computational approaches; informatics, digital health, clinical genomics, molecular biology, computational biology, and drug discovery. He will oversee and track OKRs, KPIs, and KRIs for PADQI Core and BTC Core will drive the exploration of entrepreneurial opportunities, industry partnerships, and commercialization of innovations within the alliance. He will play a crucial role in leveraging venture resources and facilitating the translation of research into tangible products and services.",
    rosario: "Rosario Isasi, J.D., M.P.H. is a Research Associate Professor of Human Genetics in the Dr. John T. Macdonald Foundation Department of Human Genetics, the John P. Hussman Institute for Human Genomics and Interdisciplinary Stem Cell Institute. She is also an Adjunct Professor of Law at UM School of Law. She has built an international reputation as a scholar with unique expertise in international comparative law, ethics, and health disparities. Prof. Isasi’s research is devoted to identifying and analyzing the social, ethical and policy dimensions of disruptive technologies (e.g., precision, genomics, and regenerative medicine). Her work centers on the prospective identification and management of actual, perceived, or emerging socio-ethical and policy issues and challenges. It also focuses on societal attitudes towards scientific innovation and the factors shaping technological uptake, public engagement, and support. Prof. Isasi’s scholarship has contributed to national/international policy reform and the development of evidence-based professional and ethical guidelines together with the creation of research ethics curricula. She will lead the Service, Outreach, Policy, and Ethics Vertical and oversee and track OKRs, KPIs, and KRIs for CEDPE and HD2RI cores, which addresses community engagement, policy development, and ethical considerations. She will provide strategic direction and oversight to initiatives to enhance service delivery, community outreach, policy advocacy, and ethical practices.",
    margaret: "Dr. Margaret Pericak-Vance is Director of the John P. Hussman Institute for Human Genomics and Executive Vice Chair of the Dr. John T. Macdonald Foundation Department of Human Genetics at the University of Miami Miller School of Medicine. Board certified by the American Board of Medical Specialties in Medical Genetics, she is a founding fellow of the American College of Medical Genetics and an elected member of the National Academy of Medicine. She has produced more than 750 scholarly publications, which have been cited at least 115,428 times in the scientific literature and earned an h-index of 142. Dr. Pericak-Vance’s laboratory made the seminal discovery of the first association of a common genetic risk variant, the apolipoprotein e4 allele (APOE-4) for late-onset Alzheimer disease, followed closely by the identification of the APOE-2 allele as a genetic protective variant for Alzheimer disease. Many of Dr. Pericak-Vance's efforts are directed towards addressing health disparities in genomics research. She directs several related genetic research projects, one on Alzheimer disease in African Americans and a second on admixed Caribbean Hispanic and Amerindian populations.",
}

export default text;