import { Box, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

import styles from './carouselStyles';
import CarouselItem from './carouselItem/CarouselItem';

function carouselSection() {
  return (
    <Box sx={styles.carouselContainer}>
      <Box className='section-one'>
        <Carousel
          animation='slide'
          interval={5000}
          indicatorContainerProps={{
            className: 'indicatorCont',
          }}
          >
          <CarouselItem url='images/iStock-1385436395.jpg' alt='Two grandparents with their granddaughter' />
          <CarouselItem url='images/iStock-1421043646.jpg' alt='A mother cooking with her children' />
          <CarouselItem url='images/iStock-1421257697.jpg' alt='A family eating dinner together' />
          <CarouselItem url='images/iStock-1459436747.jpg' alt='A mother and her daughter' />
          <CarouselItem url='images/iStock-1473305824.jpg' alt='A Grandfather with his grandson' />
        </Carousel>
      </Box>
      <Box className='section-two'>
        <Box className='content-container'>
          <Typography variant='overline' aria-label='first section' role='main' >HARMONI ALLIANCE</Typography>
          <Typography variant='heading1' role='heading' >Healthy aging for all</Typography>
          <Typography variant='heading2' role='heading'>Creating opportunities for multi-generational memories to be made.</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default carouselSection