import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import styles from './leaderStyles';
import Tag from './Tag';
import LeaderCard from './LeaderCard';
import LeaderDialog from './LeaderDialog';

function Leaders() {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  return (
    <Box className="leaders-container" sx={styles}>
      <LeaderDialog 
      closeDialog={closeDialog} 
      open={open} 
      source="dr-azizi.png"
      right="40px"
      title="Dr. Azizi Seixas"
      subtitle="Founding Director, Harmoni Alliance"
      tags={["Biomedical Researcher","Scientist","Technologist"]}
      name="azizi"
      />
      <Box className="leaders-content">
        <Box className="header">
          <Typography variant="heading1" className="margin" role='heading' >
            Our leaders in expert healthcare research
          </Typography>
          <Typography variant="body_copy">
            Leading the way in patient-centric, cutting-edge innovation
          </Typography>
        </Box>
        <Box className="leaders">
          <Box className="col-one" onClick={openDialog}>
            <Box className="img-wrapper" sx={{ marginBottom: "24px" }}>
              <Box className='azizi-colored-overlay' id="azizi-colored-overlay-display" ></Box>
              <img src="images/dr-azizi.png" className='azizi-image' alt="Dr Azizi" />
            </Box>
            <Typography variant="heading3" className='hover-text' role='heading' >Dr. Azizi Seixas</Typography>
            <Typography className="subtitle hover-text" sx={{ marginBottom: "12px" }}>
              Founding Director
            </Typography>
            <Box className="tags-container" sx={{ marginBottom: "12px" }}>
              <Tag title="Digital Technology" />
              <Tag title="Innovation" />
              <Tag title="Population Health" />
              <Tag title="Behavioral Health" />
            </Box>
            <Typography>
              "Imagine a world where healthcare is personalized, precise, and
              intuitive."
            </Typography>
          </Box>
          <Box className="col-two">
            <LeaderCard
              source="JeanLouis-Headshot.png"
              alt="Dr. Girardin Jean-Louis"
              title="Dr. Girardin Jean-Louis"
              subtitle="Education"
              tags={["Psychiatry", "Health Equity", "Neurology", "Behavioral Health", "Brain Health"]}
              right={5}
              name="jean"
            />
            <LeaderCard
              source="Tsinoremas-Headshot.png"
              alt="Dr. Nick Tsinoremas"
              title="Dr. Nick Tsinoremas"
              subtitle="Venture"
              tags={["Innovation", "Computing", "Machine Learning", "Data"]}
              right={5}
              name="nick"
            />
            <LeaderCard
              source="Ramos-Headshot.png"
              alt="Dr. Alberto Ramos"
              title="Dr. Alberto Ramos"
              subtitle="Clincal Operations, Dissemination, and eHealth"
              tags={["Clincal Medicine", "Neurology", "Dementia Research"]}
              right={5}
              name="alberto"
            />
            <LeaderCard
              source="Isasi-Headshot.png"
              alt="Rosario Isasi"
              title="Rosario Isasi, J.D., M.P.H"
              subtitle="Service, Outreach, Policy & Ethics"
              tags={["Ethics & Health Equity", "Ethical, Legal, Social Implications"]}
              right={5}
              name="rosario"
            />
            <LeaderCard
              source="PericakVance-Headshot.png"
              alt="Rosario Isasi"
              title="Dr. Margaret Pericak-Vance"
              subtitle=""
              tags={["Dementia Research" , "Brain Health", "Genetics"]}
              right={5}
              name="margaret"
            />
            <LeaderCard
              source="Freddy-Headshot.png"
              alt="Ferdinand Zizi"
              title="Ferdinand Zizi"
              subtitle=""
              tags={["Strategy" , "Leadership", "Health Systems", "Innovation"]}
              right={1}
              name="freddy"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Leaders