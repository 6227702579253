const styles = {
    carouselContainer: (theme) => ({
        backgroundColor: (theme) => theme.custom.colors.primary_navy,
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        height: '431px',
        [theme.breakpoints.down('mobile')]: {
            display: 'grid',
            gridTemplateColumns: '393px',
            gridTemplateRows: 'auto 255px',
            gridTemplateAreas: '"top" "bottom"',
            height: 'auto',
        },

        '.section-one': {
            [theme.breakpoints.down('mobile')]: {
                gridArea: 'bottom',
                maxHeight: '255px',
                overflow: 'hidden',
            },
            
            '.indicatorCont': {
                position: 'absolute',
                top: '376px',
                zIndex: '2',
            }
        },

        '.section-two': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

            '.content-container': {
                display: 'flex',
                flexDirection: 'column',
                rowGap: '24px',
                maxWidth: '500px',
                textAlign: 'left',
                color: (theme) => theme.custom.colors.primary_white,
                padding: '48px 24px'
            }
        }
    }),
    
    
}

export default styles;

