import { Box } from '@mui/material'
import React from 'react'

import styles from './tagStyles'

function tag({title}) {
  return (
    <Box className='tag-container' sx={styles}>
        {title}
    </Box>
  )
}

export default tag