const styles = (theme) => ({
    backgroundColor: (theme) => theme.custom.colors.primary_navy,
    color: (theme) => theme.custom.colors.primary_white,
    '.contact-content': {
        padding: '60px 170px',
        [theme.breakpoints.down('mobile')]: {
            padding: '40px',
        },


        '.contact-header': {
            textAlign: 'center',
            width: '520px',
            margin: 'auto',
            [theme.breakpoints.down('mobile')]: {
                width: '100%',
            }
        },

        '.contact-body': {
            width: '600px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('mobile')]: {
                width: '100%',
            },

            '.select': {
                border: '1px solid white',
                height: '42px',
                marginBottom: '20px',
                backgroundColor: theme.custom.colors.primary_navy,
                color: 'white',
                fontSize: '18px',
                padding: '5px',
                borderRadius: '4px',

                'svg': {
                    color: 'white',
                }
            },

            'fieldset': {
                border: 'none',
            },

            'button': {
                margin: 'auto',
            },

            'input[type="text"]': {
                border: (theme) => `1px solid ${theme.custom.colors.primary_white}`,
                borderRadius: '6px',
                height: '24px',
                color: (theme) => theme.custom.colors.primary_white,
                padding: '8px 12px',
            },

            '.helper-text': {
                fontFamily: 'Noto Sans',
                fontSize: '14px',
                fontStyle: 'italic',
                fontWeight: '400',
                lineHeight: '16px',
            },

            '.label': {
                marginBottom: '4px',
            },

            '.input-marginBottom': {
                marginBottom: '24px',
            },

            '.success-text': {
                textAlign: 'center'
            },
        }
    }
});

export default styles;