const styles = {
    'header-section': {
        height: '80px',
        padding: '20px 24px',
    },
    'header-content-container': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // maxWidth: "1440px",
    }
}

export default styles;