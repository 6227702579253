const styles = (theme) => ({
    '.video-content': {
        display: 'grid',
        gridTemplateColumns: 'auto 869px',
        gridTemplateRows: '431px',
        [theme.breakpoints.down('mobile')]: {
            gridTemplateColumns: '393px',
            gridTemplateRows: 'auto auto'
        },
        [theme.breakpoints.down(1080)]: {
            gridTemplateColumns: '100%',
            gridTemplateRows: 'auto'
        },

        '.col-one': {
            backgroundColor: (theme) => theme.custom.colors.primary_navy,
            color: (theme) => theme.custom.colors.primary_white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '.col-one-content': {
                width: '347px',
                textAlign: 'left',
                padding: '25px',
                [theme.breakpoints.down('mobile')]: {
                    width: 'auto',
                    padding: '40px 24px',
                },
                
                '.margin-bottom': {
                    marginBottom: '24px',
                },
            },
        },

        '.col-two': {
            backgroundColor: (theme) => theme.custom.colors.primary_white,
            [theme.breakpoints.down('mobile')]: {
                backgroundColor: theme.custom.colors.primary_navy,
                paddingBottom: '40px',
            },

            'video': {
                height: '431px',
                [theme.breakpoints.down(1080)]: {
                    width: '100%',
                    height: 'auto',
                },
            }
        }
    }
})

export default styles;