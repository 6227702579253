const styles = (theme) => ({
  display: "grid",
  width: "350px",
  gridTemplateColumns: "auto auto",
  gap: "24px",
  cursor: "pointer",
  "&:hover": {
    ".hover-text": {
      color: theme.custom.colors.primary_teal,
    },
    "#colored-overlay": {
      display: 'block',
    }
  },

  ".image-container": {
    ".image-wrapper": {
      borderRadius: "50.5px",
      display: "inline-block",
      overflow: "hidden",
      minWidth: "80px",
      maxWidth: "80px",
      minHeight: "80px",
      maxHeight: "80px",

      img: {
        // height: '116px',
        position: "relative",
        height: "80px",
      },

      ".colored-overlay": {
        display: 'none',
        minWidth: "80px",
        maxWidth: "80px",
        minHeight: "80px",
        maxHeight: "80px",
        position: "absolute",
        zIndex: "2",
        backgroundColor: theme.custom.colors.primary_teal,
        borderRadius: "50.5px",
        filter: "opacity(25%)",
      },
    },
  },

  ".details-wrapper": {
    ".tags": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "start",
      alignItems: "center",
      rowGap: (theme) => theme.custom.tag.gap,
      columnGap: (theme) => theme.custom.tag.gap,
    },

    ".subtitle": {
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontStyle: "italic",
      fontWeight: "400",
      lineHeight: "24px",
    },
  },
});

export default styles;