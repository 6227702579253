const styles = {
    backgroundColor: (theme) => theme.custom.colors.tag_background,
    borderRadius: (theme) => theme.custom.tag.border_radius,
    padding: (theme) => theme.custom.tag.padding,
    width: 'fit-content',
    fontFamily: 'Noto sans',
    fontSize: (theme) => theme.custom.tag.fontSize,
    fontWeight: '600',
    lineHeight: '16px',
};

export default styles;