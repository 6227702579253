import React from "react";
import { Dialog, Typography, Box, Button, DialogActions, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import Tag from "./Tag";
import LeaderText from "./leaderText";
import dialogStyles from "./dialogStyles";

function LeaderDialog(props) {
  const { closeDialog, open, source, right, title, subtitle, tags, name } = props;

  return (
    <Dialog open={open} onClose={closeDialog} sx={dialogStyles} fullScreen>
      <Box className="dialog-body">
        <Box className="dialog-close">
          <IconButton aria-label="delete" onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="dialog-content">
          <Box className="dialog-img-wrapper margin-bottom-24">
            {/* <Box className='colored-overlay' ></Box> */}
            {name === "azizi" ? (
              <img src={`images/AziziSeixas-Headshot.png`} alt={name} />
            ) : (
              <img src={`images/${source}`} alt={name} />
            )}
          </Box>
          <Typography variant="heading1" className="margin-bottom-24">{title}</Typography>
          <Typography variant="body_copy_20" className="margin-bottom-16">
            {subtitle}
          </Typography>
          <Box className="tag-group margin-bottom-24">
            {/* <Tag title="Biomedical Researcher" />
            <Tag title="Scientist" />
            <Tag title="Technologist" /> */}
            {tags.map((tag, index) => (
              <Tag title={tag} key={index} />
            ))}
          </Box>
          {name === "azizi" && (
            <Box>
              <p>{LeaderText.azizi1}</p>
              <p>{LeaderText.azizi2}</p>
            </Box>
          )}
          <p>{LeaderText[name]}</p>
          <DialogActions>
            <Button
              variant="outlined"
              className="margin-bottom-40"
              onClick={closeDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
}

export default LeaderDialog;
