import { Box } from '@mui/material'
import React from 'react'

import styles from './styles';

function CarouselItem({ url, alt }) {
  return (
    <Box sx={styles}>
      <img src={url} alt={alt} />
    </Box>
  );
}

export default CarouselItem