import { Box, Typography, Dialog } from '@mui/material'
import React, { useState } from 'react'

import styles from './leadercardStyles';
import Tag from './Tag';
import LeaderDialog from './LeaderDialog';

function LeaderCard({source, alt, title, subtitle, tags, right, name}) {
    const [open, isOpen] = useState(false);

    const openModal = () => {
        isOpen(true);
    }

    const closeDialog = () => {
        isOpen(false);
    }

  return (
    <Box>
      <LeaderDialog
        open={open}
        closeDialog={closeDialog}
        source={source}
        alt={alt}
        title={title}
        subtitle={subtitle}
        tags={tags}
        right={right}
        name={name}
      />
      <Box className="leader-card-container" sx={styles} onClick={openModal}>
        <Box className="image-container" role="button">
          <Box className="image-wrapper">
            <Box className="colored-overlay" id="colored-overlay"></Box>
            <img src={`images/${source}`} alt={alt} />
          </Box>
        </Box>
        <Box className="details-wrapper" role="button">
          <Typography variant="heading3" className="hover-text">
            {title}
          </Typography>
          <Typography
            className="subtitle hover-text"
            sx={{ marginBottom: "12px" }}
          >
            {subtitle}
          </Typography>
          <Box className="tags" role="button">
            {tags.map((item, index) => (
              <Tag title={item} key={index} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LeaderCard