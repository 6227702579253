import { Box, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import LoadingButton from '@mui/lab/LoadingButton';

import styles from "./contactStyles";

function Contact() {
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    area_of_interest: "",
    email: "",
  });

  const onFormDataChanged = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    })

    setSuccessMessage(false);
  };

  const onSelectChanged = (event) => {
    const selectValue = event.target.value;

    setFormData({
      ...formData,
      area_of_interest: selectValue,
    })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      area_of_interest: "",
      email: "",
    })
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          {
            name: formData.name,
            area_of_interest: formData.area_of_interest,
            email: formData.email,
          },
          process.env.REACT_APP_PUBLIC_ID
        )
        .then(() => {
          console.log("form submmited");
          console.log(formData);
          
          resetForm();
          setSuccessMessage(true);
          setLoading(false);
        });
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  return (
    <Box className="contact-container" sx={styles}>
      <Box className="contact-content">
        <Box className="contact-header">
          <Typography variant="heading1" sx={{marginBottom: '24px'}} role='heading' >Join the alliance</Typography>
          <Typography variant="heading2" sx={{marginBottom: '24px'}} role='heading' >
            Subscribe to our newsletter for the latest updates and resources
          </Typography>
        </Box>
        <Box component={"form"} onSubmit={onFormSubmit} className='contact-body'>
            <Typography component='label' htmlFor='name' variant='body_copy' className='label'>Name</Typography>
            <TextField name='name' id='name' value={formData.name} onChange={onFormDataChanged} required fullWidth className='input-marginBottom' inputProps={{'aria-required': 'true'}} />
            <Typography component='label' htmlFor='area_of_interest' variant='body_copy' className='label'>Area of Interest</Typography>
            {/* <TextField name='area_of_interest' id='area_of_interest' value={formData.area_of_interest} onChange={onFormDataChanged} fullWidth className='input-marginBottom'  /> */}
            <select
              id='area_of_interest'
              name='area_of_interest'
              value={formData.area_of_interest}
              label='Area of Interest'
              onChange={onFormDataChanged}
              className='select'
            >
              <option value='Healthcare Professional' >Healthcare Professional</option>
              <option value='Academic Institution' >Academic Institution</option>
              <option value='Industry Leader' >Industry Leader</option>
              <option value='Government Agency' >Government Agency</option>
              <option value='Non-Profit' >Non-Profit</option>
              <option value='Philanthropist' >Philanthropist</option>
              <option value='Community Advocate' >Community Advocate</option>
              <option value='Patient Group' >Patient Group</option>
            </select>
            <Typography component='label' htmlFor='email' variant='body_copy' className='label'>Email</Typography>
            <TextField name='email' id='email' value={formData.email} onChange={onFormDataChanged} required fullWidth inputProps={{'aria-required': 'true'}} />
            <Typography className='helper-text input-marginBottom'>We'll never share your email with anyone else.</Typography>
            <LoadingButton className='input-marginBottom' type='submit' name="submit" variant='contained' loading={loading} ><span>Subscribe</span></LoadingButton>
            {showSuccessMessage && <Typography className='success-text' variant="body_copy" >Thanks for subscribing!</Typography>}
        </Box>
      </Box>
    </Box>
  );
}

export default Contact